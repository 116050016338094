import React from "react";
import styled from "@emotion/styled";
import { Grid, Typography, Box } from "@mui/material";
import HalfStar from "../../assets/halfStarWhite.svg";
import HalfStarRight from "../../assets/halfStarRightWhite.svg";
import HalfStarBottom from "../../assets/halfStarBottomWhite.svg";
import AwesomeSlider from "react-awesome-slider";
import dompurify from "dompurify";
import "react-awesome-slider/dist/styles.css";
import withAutoplay from "react-awesome-slider/dist/autoplay";
const AutoplaySlider = withAutoplay(AwesomeSlider);
export default function Clients(props) {
  const Testimonials = [
    {
      quote: `This has been the smoothest move I’ve ever been a part of and the apartment is absolutely
      beautiful! I’m thankful for you guys and the hard work you all continue to put in for your
      tenants.`,
      name: "Robyn",
    },
    {
      quote: `Additionally I would like to mention/ recognize how amazing ALL your staff have been including the workers! You all have been so incredibly nice, accommodating and helpful. This move has been such a pleasant process and I am honestly loving the place!`,
      name: "Kassandra",
    },
    // {
    //   quote: `You guys have done such an amazing job and have really made such a huge milestone in my life a very pleasant one! Please pass on a huge thank you to everyone involved in the project you all have done such an amazing job and it definitely shows!!!`,
    //   name: "Kassandra",
    // },
    {
      quote: `Thank you for all your support and helping me settle into this beautiful building.`,
      name: "Candice",
    },
    {
      quote: `The move was seamless and everything was done to perfection. Happy is an understatement!`,
      name: "Kathy",
    },
    {
      quote: `The building and suite is wonderful and your staff is 5 star.`,
      name: "Debbie",
    },
  ]
    .map((item, index) => {
      if (item?.quote === "" || item?.quote === null) {
        return false;
      }
      return {
        quote: `${item.quote}`,
        client: dompurify.sanitize(`${item.name}`),
      };
    })
    .filter(item => {
      return item;
    });
  return (
    <Wrapper
      id="testimonials"
      component={Grid}
      container
      p={{ xs: "4rem 0 2rem", md: "5rem" }}
      height={{ xs: 665, md: 415 }}
      justifyContent={"center"}
      alignContent={"flex-start"}
    >
      <Grid
        item
        component={Typography}
        sx={{ lineHeight: 1.5 }}
        xs={8}
        md={12}
        variant="h2"
        align="center"
        color="common.white"
      >
        What our clients are saying
      </Grid>
      <AutoplaySlider
        play={false}
        cancelOnInteraction={false} // should stop playing on user interaction
        interval={4000}
        bullets={false}
        organicArrows={false}
        // organicArrows={props.showTextNav}
      >
        {Testimonials &&
          Testimonials.map((item, index) => {
            return (
              <div key={item.quote}>
                <TestimonialContainer
                  paragraph
                  variant="body1"
                  align="center"
                  color="secondary.main"
                >
                  {item.quote}
                </TestimonialContainer>
                <Typography paragraph variant="body1" align="center" color="common.white">
                  <strong>– {item.client}</strong>
                </Typography>
              </div>
            );
          })}
      </AutoplaySlider>
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  background-image: url(${HalfStarBottom});
  background-repeat: no-repeat;
  background-position: bottom;
  background-color: ${props => props.theme.palette.primary.main};
  ${props => props.theme.breakpoints.up("md")} {
    background-image: url(${HalfStar}), url(${HalfStarRight});
    background-position: left, right;
  }
  .awssld__content,
  .awssld__box,
  .awssld__wrapper,
  .awssld__container {
    height: inherit;
  }

  .awssld {
    height: 375px;
    ${props => props.theme.breakpoints.up("md")} {
      height: 250px;
    }
    --timer-height: 0;
    --organic-arrow-thickness: 4px;
    --organic-arrow-height: 40px;
    --slider-height-percentage: null;
    --loader-bar-color: grey;
    --loader-bar-height: 0;
    --control-button-width: 10%;
    --control-button-height: 25%;
    --control-button-opacity: 0.5;
    --control-button-hover-opacity: 0.75;
    --control-button-background: transparent;
    --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
    --slider-transition-duration: 575ms;
    --organic-arrow-color: #6a6a6a;
    --organic-arrow-border-radius: 0;
    --control-bullet-color: #6a6a6a;
    --control-bullet-active-color: #6a6a6a;
    --content-background-color: none;
  }
  .awssld__timer {
    opacity: 0;
    pointer-events: none;
  }
`;
const TestimonialContainer = styled(Typography)`
  max-width: 70vw;
  ${props => props.theme.breakpoints.up("md")} {
    max-width: 700px;
  }
`;
