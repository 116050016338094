import { AppBar, Toolbar, Box, Grid, Hidden } from "@mui/material";
import { Link, animateScroll as scroll } from "react-scroll";
import { Menu } from "@mui/icons-material";
import { menuData } from "../../data/menuData";
export default function Navigation() {
  const pages = [
    { linkText: "About", url: "about" },
    { linkText: "Projects", url: "projects" },
    { linkText: "Testimonials", url: "testimonials" },
    { linkText: "Contact", url: "contact" },
  ];

  return (
    <AppBar color="primary">
      <Toolbar
        disableGutters
        component={Grid}
        container
        alignContent={"center"}
        justifyContent={"space-evenly"}
        // sx={{ maxWidth: "65vw" }}

        pl={"12%"}
        pr={"12%"}
      >
        <Hidden smDown>
          {menuData.map((menuItem, index) => {
            if (menuItem.touch) {
              //Only applicable for touch screens
              return null;
            }
            return (
              <Grid
                key={menuItem.to}
                activeClass="active"
                spy={true}
                smooth={true}
                offset={menuItem.options.offset}
                duration={menuItem.options.duration}
                component={Link}
                item
                color="common.white"
                to={menuItem.to}
              >
                {menuItem.label}
              </Grid>
            );
          })}
        </Hidden>
        <Hidden smUp>
          <Menu />
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}
