import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Typography, Button } from "@mui/material";

import ReactMarkdown from "react-markdown";

import RenderTypography from "./RenderTypography";
import RenderButton from "./RenderButton";
import DefaultOrderedList from "./DefaultOrderedList";
import DefaultUnorderedList from "./DefaultUnorderedList";

function RenderMarkdown({ TextWrapperComponent, paragraph, align, style, sx = {}, ...props }) {
  //TODO add style
  return (
    <ReactMarkdown
      components={{
        p: props => (
          <RenderTypography
            paragraph={paragraph}
            sx={[...(Array.isArray(sx) ? sx : [sx])]}
            style={style}
            align={align}
            {...props}
          />
        ),
        h1: props => (
          <RenderTypography
            paragraph={paragraph}
            sx={[...(Array.isArray(sx) ? sx : [sx])]}
            style={style}
            align={align}
            {...props}
          />
        ),
        h2: props => (
          <RenderTypography
            paragraph={paragraph}
            sx={[...(Array.isArray(sx) ? sx : [sx])]}
            style={style}
            align={align}
            {...props}
          />
        ),
        h3: props => (
          <RenderTypography
            paragraph={paragraph}
            sx={[...(Array.isArray(sx) ? sx : [sx])]}
            style={style}
            align={align}
            {...props}
          />
        ),
        h4: props => (
          <RenderTypography
            paragraph={paragraph}
            sx={[...(Array.isArray(sx) ? sx : [sx])]}
            style={style}
            align={align}
            {...props}
          />
        ),
        h5: props => (
          <RenderTypography
            paragraph={paragraph}
            sx={[...(Array.isArray(sx) ? sx : [sx])]}
            style={style}
            align={align}
            {...props}
          />
        ),
        h6: props => (
          <RenderTypography
            paragraph={paragraph}
            sx={[...(Array.isArray(sx) ? sx : [sx])]}
            style={style}
            align={align}
            {...props}
          />
        ),
        ol: props.orderedListComp ? props.orderedListComp : DefaultOrderedList,
        ul: DefaultUnorderedList,
        a: RenderButton,
        button: RenderButton,
      }}
      // sx={[...(Array.isArray(sx) ? sx : [sx])]}
      style={props?.style}
      className={props?.className}
      paragraph={props?.paragraph}
    >
      {props?.body}
    </ReactMarkdown>
  );
}

RenderMarkdown.propTypes = {};

export default RenderMarkdown;
