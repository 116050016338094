import React from "react";
import styled from "@emotion/styled";
import { Grid, Typography, useTheme } from "@mui/material";
import HalfStar from "../../assets/halfStar.svg";
import HalfStarBottom from "../../assets/halfStarBottom.svg";
import Logo from "../../assets/logo.svg";
export default function Intro({ showTextNav, ...props }) {
  const theme = useTheme();
  return (
    <Wrapper
      container
      component="section"
      flexDirection={"column"}
      justifyContent={"center"}
      alignContent={"center"}
    >
      <Grid item align="center" mt={{ xs: "-12rem", sm: "unset" }}>
        <img src={Logo} style={{ width: "300px", height: "auto" }} />
      </Grid>

      <Grid
        item
        component={Typography}
        variant="h1"
        color="secondary.main"
        align="center"
        style={{ textWrap: "nowrap" }}
        mt={{ xs: "3rem", sm: "unset" }}
      >
        Welcome to
        <br />
        <span style={{ color: theme.palette.primary.main }}>Bright Star</span>
      </Grid>
    </Wrapper>
  );
}
const Wrapper = styled(Grid)`
  height: 500px;
  ${props => props.theme.breakpoints.down("sm")} {
    background-image: url(${HalfStarBottom});
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 70svw;
  }
  ${props => props.theme.breakpoints.up("md")} {
    height: 630px;
  }
  > .MuiTypography-h1 {
    // height: clamp(100px, calc(16.5vh), 300px);
    ${props => props.theme.breakpoints.up("sm")} {
      background-image: url(${HalfStar});
      background-repeat: no-repeat;
      background-size: contain;
      background-position: left center;
      width: 100%;
      padding: 5rem 0;
    }
  }
`;
