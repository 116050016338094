import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@mui/material/";
import { responsiveFontSizes } from "@mui/material/styles";

const font = "'gothambook', sans-serif";

const titleFont = "'gothambold', sans-serif";

const defaultTheme = createMuiTheme({
  maxWidth: "1440px",
  // activeColor: "#6098AF",
  palette: {
    primary: {
      main: "rgba(218,171,54,1)",
      light: "rgba(114,114,114,1)",
      dark: "rgba(205,113,32,1)",
    },
    secondary: {
      main: "rgba(97,98,100,1)",
      light: "rgb(242,242,242)",
      charcoal: "rgb(37,42,52)",
    },
    lb: { main: "#ffffff", contrastText: "#000000", dark: "#727272" }, //light button
    // common: {
    //   lightBlack: "#1b1c1e",
    //   backgroundGrey: "rgba(122,122,115,1)",
    //   backgroundLightGrey: "rgba(227,227,224,1)",
    //   darkGrey: "rgb(55, 54, 56)",
    // },
    action: {
      //This is a reference. You can use theme.palette.action.active etc.
      // active: "rgb(61,63,64)",
      // hover: "rgb(61,63,64)",
      // hoverOpacity: 0.1,
      // selected: "rgba(192, 202, 200, 0.54)",
      // selectedOpacity: 0.08,
      // disabled: "rgba(0, 0, 0, 0.26)",
      // disabledBackground: "rgba(0, 0, 0, 0.12)",
      // disabledOpacity: 0.38,
      // focus: "rgba(0, 0, 0, 0.12)",
      // focusOpacity: 0.12,
      // activatedOpacity: 0.12,
    },
    text: {
      primary: "rgba(97,98,100,1)", //#727272
      secondary: "rgba(0, 0, 0, 0.54)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
});

export const theme = responsiveFontSizes(
  createMuiTheme({
    ...defaultTheme,
    components: {
      // Style sheet name
      MuiContainer: {
        styleOverrides: {
          root: {
            // use this to adjust the margins to whatever the "designer" might throw at you
            // paddingLeft: "2rem",
            // paddingRight: "2rem",
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {},
      },

      MuiAppBar: {
        colorSecondary: { backgroundColor: defaultTheme.palette.common.backgroundGrey },
      },
      MuiTabScrollButton: {
        root: { backgroundColor: defaultTheme.palette.primary.scrollButton },
      },
      MuiButton: {
        // Name of the rule
        // contained: {
        //   color: defaultTheme.palette.primary.main,
        //   backgroundColor: defaultTheme.palette.common.white,
        // },
        root: {
          borderRadius: 0,
          height: 42,
        },
        label: {
          // Some CSS
          fontSize: ".8rem",
        },
      },

      MuiCardActions: {
        // root: {
        //   backgroundColor: defaultTheme.palette.primary.slate,
        //   color: defaultTheme.palette.common.white,
        // },
      },
      MuiInputBase: {
        defaultProps: {
          input: {
            backgroundColor: defaultTheme.palette.common.white,
          },
        },
      },
    },

    typography: {
      htmlFontSize: 17,
      fontFamily: font,
      h1: {
        // color: defaultTheme.palette.common.white,
        fontSize: "5.725rem",
        fontFamily: titleFont,
        // fontWeight: 800,
        // fontStyle: "italic",
        textTransform: "uppercase",
      },
      h2: {
        // whiteSpace: "nowrap",
        fontSize: "2.5rem",
        fontFamily: titleFont,
        lineHeight: 3,
        textTransform: "uppercase",
      },
      body1: {
        color: "rgb(114,114,114, 1)",
        // maxWidth: defaultTheme.breakpoints.values.lg,
        lineHeight: 2,
      },
    },
  }),
  { factor: 3 }
);
