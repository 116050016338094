import React from "react";
import Champagne from "../assets/45.jpg";
import { Box } from "@mui/material";
import styled from "@emotion/styled";

export default function AngledImage({ image, ...props }) {
  return (
    <Wrapper>
      <img src={image} alt="Bright Star Properties" role="presentation" />
    </Wrapper>
  );
}
const Wrapper = styled(Box)`
  // min-height: 380px;
  height: auto;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }
  > .right {
    position: relative;

    width: 53%;
    height: 100%;
    transform: skew(-7.5deg);
    background-color: red;
    overflow: hidden;
    // img {
    //   position: absolute;
    //   top: 0;
    //   right: -5%;
    //   width: 105%;
    //   height: 100%;
    //   object-fit: cover;
    //   transform: skew(7.5deg);
    //   transorm-origin: top left;
    // }
  }
`;
