import React from "react";
import styled from "@emotion/styled";
import Obfuscate from "react-obfuscate";
import { Grid, Typography } from "@mui/material";
export default function Contact() {
  return (
    <Grid
      id="contact"
      container
      justifyContent={"center"}
      alignItems={"center"}
      alignContent={"center"}
      bgcolor={"secondary.main"}
      p={{ xs: 4, md: 16 }}
      height={{ xs: "calc(100dvh - 3rem)", sm: "max-content" }}
    >
      <Grid
        item
        component={Typography}
        align="left"
        xs={12}
        // md={10}
        variant="h2"
        color={"common.white"}
        sx={{ lineHeight: { xs: 1.25, md: 0 }, pb: { xs: "1rem", md: "3rem" } }}
      >
        Get in touch with bright star
      </Grid>
      <Grid
        item
        component={Typography}
        align="left"
        variant="h6"
        xs={12}
        // md={10}
        color={"primary.main"}
      >
        <strong> Residential Leasing Inquiries:</strong>
      </Grid>
      <Grid
        item
        component={Typography}
        align="left"
        variant="body1"
        xs={12}
        // md={10}
        color={"common.white"}
      >
        (403) 930-6900
      </Grid>
      <Grid
        item
        component={Typography}
        align="left"
        variant="body1"
        xs={12}
        // md={10}
        color={"common.white"}
      >
        <Obfuscate
          style={{ textDecoration: "none", color: "white" }}
          email="info@brightstarproperties.ca"
          // headers={{
          //   subject: "",
          //   body: "",
          // }}
        />
      </Grid>
    </Grid>
  );
}
