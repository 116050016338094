import React from "react";
import ImageGallery from "react-image-gallery";
import styled from "@emotion/styled";
import "./image-gallery.css";

//TODO use the strapiImageObject to build images array with srcset and sizes prop.
export default function Gallery({ options, ...props }) {
  const images = options?.data?.map((image, index) => {
    // xlarge: 1920,
    // large: 1000,
    // medium: 750,
    // small: 500,
    // xsmall: 64,
    const returnObject = {
      // TODO use srcset
      // TODO use sizes
      id: index,
      srcSet: `${image?.formats?.small?.url} 500w,${image?.formats?.medium?.url} 750w,${image?.url} 1000w, ${image?.url} 1200w`,
      // sizes: "(min-width: 768px) 50vw, 100vw",
      original: `${image?.url}`,
      // renderItem: CustomRender,
      thumbnail: `${image?.formats?.thumbnail?.url}`,
      fullscreen: `${image?.url}`,
    };

    return returnObject;
  });

  return <ImageGallery items={images} {...options.settings} {...props} />;
}

const CustomRender = styled.img`
  width: 100%;
  min-height: 200px;
  object-fit: cover;
  ${props => props.theme.breakpoints.up("sm")} {
    min-height: 90svh;
  }
`;
