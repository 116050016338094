import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import {
  Grid,
  Typography,
  Button,
  Hidden,
  Container,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ArrowRight, ChevronRight } from "@mui/icons-material";
import Deville from "../../assets/Deville.jpg";
import Blume from "../../assets/Blume.jpg";
import { motion } from "framer-motion";
import Gallery from "../Gallery";
import Calais from "../../data/Gallery/calais.json";
import Gatestone from "../../data/Gallery/gatestone.json";
import TheGates from "../../data/Gallery/thegates.json";
import Champagne from "../../data/Gallery/champagne.json";
import Quarters from "../../data/Gallery/quarters.json";
import Laurier from "../../data/Gallery/laurier.json";
import Gates2 from "../../data/Gallery/gates2.json";
import ChevronRightImage from "../../assets/ChevronRight.svg";
import ChevronLeftImage from "../../assets/ChevronLeft.svg";
import ChevronRightWhiteImage from "../../assets/ChevronRightWhite.svg";
import ChevronLeftWhiteImage from "../../assets/ChevronLeftWhite.svg";

import Triangle from "../../assets/logoTriangle.svg";

export default function Projects(props) {
  const [galleryData, setGalleryData] = useState(projectsArray);
  const [currentGalleryData, setCurrentGalleryData] = useState([]);
  const [currentGalleryLogo, setCurrentGalleryLogo] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [galleryIndex, setGalleryIndex] = useState(0);
  const numberOfProjects = projectsArray.length;

  useEffect(() => {
    setCurrentGalleryData(projectsArray[0]?.data);
    setCurrentGalleryLogo(projectsArray[0]?.logo);
    return () => {
      return true;
    };
  }, []);

  const theme = useTheme();

  const isTouch = useMediaQuery(theme.breakpoints.down("md"));
  // useEffect(() => {
  //   setGalleryData(currentGalleryData[galleryIndex]?.data);
  // }, [galleryIndex]);

  useEffect(() => {
    setCurrentGalleryData(galleryData[galleryIndex]?.data);
    setCurrentGalleryLogo(projectsArray[galleryIndex]?.logo);
  }, [galleryIndex, galleryData]);

  const handleOnScreenChange = fullScreen => {
    setIsFullScreen(() => fullScreen);
    return fullScreen;
  };

  const handleGalleryClick = event => {
    setGalleryIndex(prev => {
      const inputValue = Number(event.currentTarget.dataset.value);
      const prevValue = Number(prev);
      const newValue = prevValue + inputValue;
      if (newValue < 0 || newValue > numberOfProjects - 1) {
        return prevValue;
      }

      return newValue;
    });
  };

  const itemRenderer = item => {
    return (
      <>
        {!isTouch && item.id === 0 && (
          <LogoSlideWrapper
            data-id={item.id}
            // className="print"
            description={item.description}
            fullscreen={item.fullscreen}
            // handleImageLoaded={this.handleImageLoaded}
            src={`${item.original}`}
            alt={item.originalAlt}
            originalHeight={item.originalHeight}
            originalWidth={item.originalWidth}
            originalTitle={item.originalTitle}
            sizes={item.sizes}
            loading={item.loading}
            srcSet={item.srcSet}
          />
        )}
        {isTouch && (
          <ImageWrapper
            data-id={item.id}
            // className="print"
            description={item.description}
            fullscreen={item.fullscreen}
            // handleImageLoaded={this.handleImageLoaded}
            src={item.original}
            alt={item.originalAlt}
            originalHeight={item.originalHeight}
            originalWidth={item.originalWidth}
            originalTitle={item.originalTitle}
            sizes={item.sizes}
            loading={item.loading}
            srcSet={item.srcSet}
          />
        )}
        {!isTouch && item.id > 0 && (
          <ImageWrapper
            data-id={item.id}
            // className="print"
            description={item.description}
            fullscreen={item.fullscreen}
            // handleImageLoaded={this.handleImageLoaded}
            src={item.original}
            alt={item.originalAlt}
            originalHeight={item.originalHeight}
            originalWidth={item.originalWidth}
            originalTitle={item.originalTitle}
            sizes={item.sizes}
            loading={item.loading}
            srcSet={item.srcSet}
          />
        )}
      </>
    );
  };

  // LogoSlideWrapper is an alternate used when I need to put the logo on the image
  const LogoSlideWrapper = styled.div`
    position: relative;
    object-fit: ${isFullScreen ? "contain" : null};
    width: 100svw;

    background-image: url(${currentGalleryLogo}), url(${props => props.src});
    background-size: 200px, ${isFullScreen ? "contain" : "cover"};
    background-repeat: no-repeat;
    background-position: bottom left, bottom center;

    height: ${isFullScreen ? "70vh" : "235px"};

    ${props => props.theme.breakpoints.up("sm")} {
      height: ${isFullScreen ? "100vh" : "352px"};
    }

    ${props => props.theme.breakpoints.up("md")} {
      width: ${isFullScreen ? "auto" : "1000px"};
      background-image: ${isFullScreen ? null : `url(${currentGalleryLogo})`},
        url(${props => props.src});
      background-size: 635px, ${isFullScreen ? "contain" : "cover"};
      background-repeat: no-repeat;
      background-position: bottom left, bottom center;
      height: ${isFullScreen ? "90vh" : "566px"};
    }
  `;
  const ImageWrapper = styled.img`
    position: relative;
    object-fit: cover;
    width: 100svw;

    // background-image: url(${props => props.src});
    // background-size: ${isFullScreen ? "contain" : "cover"};
    // background-repeat: no-repeat;
    // background-position: center;

    height: ${isFullScreen ? "70vh" : "235px"};

    ${props => props.theme.breakpoints.up("sm")} {
      height: ${isFullScreen ? "100vh" : "352px"};
    }
    ${props => props.theme.breakpoints.up("md")} {
      height: ${isFullScreen ? "90vh" : "470px"};
    }
    ${props => props.theme.breakpoints.up("md")} {
      width: ${isFullScreen ? "auto" : "1000px"};
      height: ${isFullScreen ? "90vh" : "566px"};
    }
  `;

  return (
    <Grid
      container
      id="projects"
      bgcolor="secondary.light"
      justifyContent={"center"}
      alignItems={"center"}
      p={{ xs: "1rem 0 0", md: "4rem 0 0" }}
    >
      <Typography
        component={Grid}
        item
        variant="h2"
        color="secondary.main"
        align="center"
        sx={{ lineHeight: { xs: 1.2, md: 0 }, mt: "3rem", mb: "3rem" }}
      >
        Discover Bright Star’s Latest Projects
      </Typography>

      <Grid container rowGap={8} columnGap={8} justifyContent={"space-evenly"}>
        <Grid item xs={12} sm={6} md={5}>
          <Grid
            //deville
            container
            flexDirection={"column"}
            rowGap={5}
            alignContent={"space-between"}
            component={Container}
          >
            <Grid
              item
              xs={12}
              component="a"
              href="https://liveatdeville.ca"
              target="Bright Star"
              alt="Live at deVille"
            >
              {/* TODO Make a styled component for this */}
              {/* width: "clamp(286px,100vw,494px)",
                  height: "286px",
                  width: "clamp(286px,calc(100vw - 4rem),494px)",
                  height: "auto", */}
              <StyledImage
                whileHover={{
                  scale: 1.05,
                  transition: { duration: 0.25 },
                }}
                whileTap={{ scale: 1.1 }}
                src={Deville}
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                }}
              />
            </Grid>
            <Grid
              item
              component={Typography}
              color={"secondary.main"}
              xs={12}
              variant="body1"
              align="left"
            >
              Welcome to deVille, a new rental development in the heart of Quarry Park. deVille is
              attracting residents seeking a comfortable, amenity-rich and pet-friendly lifestyle.
              Three thirteen storey residential towers offer intelligent design, and at your
              doorstep is the convenience of The Market at Quarry Park.
            </Grid>
            <Button
              variant="contained"
              sx={{ width: "fit-content" }}
              alt="Live at deVille"
              href="https://liveatdeville.ca/"
              target="Bright Star"
              style={{ color: "white", fontWeight: "bold" }}
            >
              Visit Deville
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={6} md={5}>
          <Grid
            //Blume
            container
            flexDirection={"column"}
            alignContent={"space-between"}
            rowGap={5}
            component={Container}
          >
            <Grid
              component="a"
              xs={12}
              href="https://liveatblume.ca"
              target="Bright Star"
              alt="Live at Blume"
              style={{
                width: "clamp(286px,100vw,494px)",
              }}
            >
              <StyledImage
                whileHover={{
                  scale: 1.05,
                  transition: { duration: 0.25 },
                }}
                whileTap={{ scale: 1.1 }}
                src={Blume}
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                }}
                // style={{
                //   width: "clamp(286px,100vw,494px)",
                //   height: "286px",
                //   objectFit: "cover",
                // }}
              />
            </Grid>
            <Grid
              item
              component={Typography}
              color={"secondary.main"}
              xs={12}
              variant="body1"
              align="left"
            >
              Blume is a new residential development conveniently positioned near the Dalhousie LRT
              Station and the Dalhousie Station Shopping Centre. Phase I includes two towers with
              exceptional views from many residences. Blume includes numerous amenities like a
              tenant social room, dog wash and spa, and a heated and secure underground parkade.
            </Grid>

            <Button
              href="https://liveatblume.ca"
              target="Bright Star"
              variant="contained"
              alt="Live at Blume"
              sx={{ width: "fit-content" }}
              style={{ color: "white", fontWeight: "bold" }}
            >
              Visit Blume
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          <Hidden mdDown>
            <Grid item xs={2} md={1}>
              <Button
                sx={{ height: "75%" }}
                data-value="-1"
                variant="text"
                color="secondary"
                onClick={handleGalleryClick}
                alt="Next Project"
                // endIcon={ChevronRight}
              >
                <img src={ChevronLeftImage} width="50%" height="auto" alt="Next Project" />
              </Button>
            </Grid>
          </Hidden>

          <Hidden mdUp>
            <Grid item xs={12}>
              <Grid container bgcolor={{ xs: "rgb(218, 171, 54)", md: "unset" }}>
                <Grid item xs={2} md={1}>
                  <Button
                    sx={{ height: "100%" }}
                    data-value="-1"
                    variant="text"
                    // color="common.white"
                    onClick={handleGalleryClick}
                    alt="Next Project"
                    // endIcon={ChevronRight}
                  >
                    <img src={ChevronLeftWhiteImage} width="50%" height="auto" alt="Next Project" />
                  </Button>
                </Grid>

                <Grid item align="center" xs={8} sx={{ m: "3rem 0" }}>
                  {/* Touch Logo */}

                  <img
                    src={`${currentGalleryLogo.replace(
                      /uploads\/ProjectLogos\//,
                      "uploads/ProjectLogos/noTriangle/"
                    )}`}
                    width="90%"
                    height="auto"
                    style={{ minHeight: 100, maxHeight: 100, objectFit: "contain" }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Button
                    sx={{ height: "100%" }}
                    data-value="1"
                    variant="text"
                    // color="common.white"
                    onClick={handleGalleryClick}
                    alt="Next Project"
                    // endIcon={ChevronRight}
                  >
                    <img
                      src={ChevronRightWhiteImage}
                      width="50%"
                      height="auto"
                      alt="Next Project"
                    />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
          <GalleryWrapper id="GalleryWrapper" isFullScreen={isFullScreen} item xs={12} md={10}>
            <Gallery
              options={{
                data: currentGalleryData,
                settings: {
                  showThumbnails: true,
                  // thumbnailPosition: isPhone ? "bottom" : "right",
                  thumbnailPosition: "bottom",
                  showBullets: false,
                  showIndex: true,
                  showPlayButton: false,
                  autoPlay: false,
                  slideInterval: 5000,
                  renderItem: itemRenderer,
                  onScreenChange: handleOnScreenChange,
                  // onSlide: handleOnSlide,
                  slideOnThumbnailOver: true,
                  useBrowserFullscreen: false,
                  showNav: false,
                  // renderLeftNav: (onClick, disabled) => {
                  //   return <LeftArrow onClick={onClick} disabled={disabled} />;
                  // },
                  // renderRightNav: (onClick, disabled) => {
                  //   return <RightArrow onClick={onClick} disabled={disabled} />;
                  // },
                },
              }}
            />
            <Container>
              <Typography variant={"h6"} paragraph>
                {galleryData[galleryIndex].name}
              </Typography>
              <Typography variant={"caption2"} paragraph>
                {galleryData[galleryIndex].type} <Spacer /> Completed{" "}
                {galleryData[galleryIndex].completed}
                <Spacer />
                Please contact us for more information.
                {/* {galleryData[galleryIndex].type} */}
              </Typography>
            </Container>
          </GalleryWrapper>
          <Hidden mdDown>
            <Grid item xs={2} md={1} textAlign={"right"}>
              <Button
                sx={{ height: "75%" }}
                data-value="1"
                variant="text"
                color="secondary"
                onClick={handleGalleryClick}
                alt="Next Project"
                // endIcon={ChevronRight}
              >
                <img src={ChevronRightImage} width="50%" height="auto" alt="Next Project" />
              </Button>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </Grid>
  );
}

const StyledImage = styled.img`
  width: clamp(286px, 100vw, 494px);
  height: 286px;
  ${props => props.theme.breakpoints.up("md")} {
    width: clamp(286px, calc(100vw - 4rem), 494px);
    height: auto;
  }
`;

const Spacer = styled.span`
  &:after {
    content: "|";
  }
  margin: 1rem;

  font-size: 1rem;
`;
const GalleryWrapper = styled(Grid)`
  margin-bottom: 3rem;
  margin-top: 0;
  ${props => props.theme.breakpoints.up("md")} {
    margin-bottom: 5rem;
    margin-top: 3rem;
  }
  .image-gallery {
    margin-bottom: 2rem;
  }
  .image-gallery-slide {
    width: 100%;
  }
  .image-gallery-slides {
    height: ${props => (props.isFullScreen ? "70vh" : "235px")};

    ${props => props.theme.breakpoints.up("sm")} {
      height: ${props => (props.isFullScreen ? "100vh" : "352px")};
    }
    ${props => props.theme.breakpoints.up("md")} {
      height: ${props => (props.isFullScreen ? "90vh" : "470px")};
    }
    ${props => props.theme.breakpoints.up("md")} {
      height: ${props => (props.isFullScreen ? "90vh" : "566px")};
    }
  }
`;

// TODO alternatively the logo could be added here directly instead of using the json file
// Might make more sense that way.

const projectsArray = [
  {
    data: Gates2.images,
    logo: Gates2.logo,
    name: "Gates II Collection",
    type: "Condominium",
    completed: "2019",
    sort: 0,
  },
  {
    data: Calais.images,
    logo: Calais.logo,
    name: "The Calais Villas",
    type: "Bungalow Villas",
    completed: "2019",
    sort: 0,
  },
  {
    data: Quarters.images,
    logo: Quarters.logo,
    name: "The Quarters",
    type: "Rental",
    completed: "2018",
    sort: 0,
  },
  {
    data: Gatestone.images,
    logo: Gatestone.logo,
    name: "Gatestone Townhome Collection",
    type: "Townhomes",
    completed: "2018",
    sort: 0,
  },
  {
    data: Champagne.images,
    logo: Champagne.logo,
    name: "Champagne",
    type: "Condominium",
    completed: "2017",
    sort: 0,
  },
  {
    data: TheGates.images,
    logo: TheGates.logo,
    name: "The Gates",
    type: "Condominium",
    completed: "2015",
    sort: 0,
  },
  {
    data: Laurier.images,
    logo: Laurier.logo,
    name: "The Laurier",
    type: "Rental",
    completed: "2014",
    sort: 0,
  },
];
