import React from "react";
import { Grid, Typography, Hidden, Container } from "@mui/material";
import styled from "@emotion/styled";
import Quarters from "../../assets/Quarters.jpg";
import Champagne from "../../assets/Champagne.jpg";

export default function About() {
  return (
    <AboutWrapper
      component={Container}
      id="about"
      container
      justifyContent={"space-around"}
      alignContent={"center"}
      alignItems={"center"}
      p={{ xs: "3rem 0", md: "5rem 0" }}
    >
      <Hidden mdDown>
        {/* desktop */}
        <Grid
          item
          xs={12}
          md={3.5}
          sx={{ position: "relative", height: "max-content", paddingLeft: 0, paddingTop: 0 }}
        >
          <Grid container justifyContent={"space-around"} alignContent={"space-evenly"}>
            <Grid item xs={6} md={12}>
              <img src={Quarters} />
            </Grid>
            <Grid item xs={6} md={12}>
              <img src={Champagne} />
            </Grid>
          </Grid>
        </Grid>
      </Hidden>

      <Grid component={Container} p={{ md: 0 }} item xs={12} md={7.5}>
        <Typography
          variant="h2"
          color="secondary.main"
          paragraph
          sx={{ lineHeight: 1.5 }}
          align="left"
        >
          About Bright Star
        </Typography>
        <Typography variant="body1" paragraph color="secondary.dark" align="left">
          <strong>
            Bright Star Management Corporation delivers value-enhanced, residential leasing
            opportunities for tenants seeking newly constructed properties in outstanding locations.
          </strong>
        </Typography>
        <Typography variant="body1" paragraph color="secondary.dark" align="left">
          Tour our developments and discover the attributes that make Bright Star a premier
          residential destination. Bright Star is your home for intelligently designed floorplans,
          striking architecture, and locations that are convenient and close to what matters most.
          Bright Star properties offer air conditioning, in-suite laundry, views and vistas from
          private patios, steps to restaurants and shops, the ride and go simplicity of transit, and
          secure and heated underground parkades. But those advantages are just the start. Read our
          testimonials from people like you and discover how the Bright Star team is connecting
          Calgarians to a better way to live, and a better way to lease. Bright Star Management, the
          premier name in residential leasing.
        </Typography>
        <StyledUnorderedList>
          <li>
            <Typography align="left">Secure and heated private parkades</Typography>
          </li>
          <li>
            <Typography align="left">Impressive views and vistas</Typography>
          </li>
          <li>
            <Typography align="left">Air-conditioned residences</Typography>
          </li>
          <li>
            <Typography align="left">Intelligent design and architecture</Typography>
          </li>
          <li>
            <Typography align="left">Convenient locations</Typography>
          </li>
          <li>
            <Typography align="left">Transit-friendly</Typography>
          </li>
          <li>
            <Typography align="left">Amenities like dog washes and social common rooms</Typography>
          </li>
        </StyledUnorderedList>
      </Grid>
      <Hidden mdUp>
        <Grid item align={"center"} xs={6} md={12}>
          <img src={Quarters} />
        </Grid>
        <Grid item align={"center"} xs={6} md={12}>
          <img src={Champagne} />
        </Grid>
      </Hidden>
    </AboutWrapper>
  );
}
const AboutWrapper = styled(Grid)`
  position: relative;

  img {
    width: clamp(136px, 95%, 256px);
    height: auto;
  }
  ${props => props.theme.breakpoints.up("md")} {
    height: max-content;
  }
`;
const StyledUnorderedList = styled.ul`
  padding-inline-start: 1.1rem;

  li {
    ::marker {
      color: ${props => props.theme.palette.primary.main};
    }
  }
`;
