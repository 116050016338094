import React, { useState, useEffect } from "react";
import {
  Typography,
  Container,
  Grid,
  useTheme,
  Button,
  Hidden,
  useMediaQuery,
} from "@mui/material";
import styled from "@emotion/styled";

import { initGA } from "./components/Tracking";
import Navigation from "./components/Navigation";
import MobileMenu from "./components/MobileMenu";
import Logo from "./assets/logo.svg";
import HalfStar from "./assets/halfStar.svg";

import Skewed from "./assets/Skewed.jpg";
import ClientAngled from "./assets/ClientAngled.svg";

import { createBrowserRouter, RouterProvider, useLoaderData } from "react-router-dom";
import Intro from "./components/Intro";
import AngledImage from "./components/AngledImage";
import RenderMarkdown from "./components/RenderMarkdown";
import Quarters from "./assets/Quarters.jpg";
import Champagne from "./assets/Champagne.jpg";
import Clients from "./components/Clients";
import About from "./components/About";
import Contact from "./components/Contact";
import Projects from "./components/Projects";

let router = createBrowserRouter([
  {
    path: "/",
    loader: () => ({ message: "Hello Data Router!" }),
  },
]);
function App() {
  const theme = useTheme();

  const showTextNav = useMediaQuery(theme.breakpoints.up("lg"));
  useEffect(() => {
    initGA([
      {
        trackingId: "G-1RPR00PQHW",
      },
    ]);
  }, []);
  return (
    <Container disableGutters sx={{ pt: "4rem" }}>
      {showTextNav && <Navigation />}

      {!showTextNav && <MobileMenu />}
      <Intro showTextNav={showTextNav} />

      <Projects />

      <AngledImage image={Skewed} />

      <About />
      <Clients showTextNav={showTextNav} />

      <AngledImage image={ClientAngled} />

      <Contact />
    </Container>
  );
}

export default App;
