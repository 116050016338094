import React from "react";
import styled from "@emotion/styled";

import { AppBar, Toolbar, IconButton, Grid, Drawer, Typography } from "@mui/material";
import { Link, animateScroll as scroll } from "react-scroll";

import { Event } from "../Tracking";
import MenuIcon from "@mui/icons-material/Menu";
import Close from "@mui/icons-material/Close";
import HalfStarBottom from "../../assets/halfStarBottomWhite.svg";
import LogoSVG from "../../assets/logo.svg";
import LogoReversed from "../../assets/logo.svg";
import { menuData } from "../../data/menuData";

export default function MobileMenu({
  setIsActive,
  isActive,
  handleExpertiseSubClick,
  expertiseFilter,
  data,
  ...props
}) {
  const [open, setOpen] = React.useState(false);

  const handleToggle = event => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setOpen(!open);
    return true;
  };

  const createEvent = event => {
    Event("Nav", "click", event.currentTarget.text);
    return true;
  };

  const handleOnClick = event => {
    createEvent(event);
    setOpen(false);
    // handleExpertiseSubClick(event);
    return true;
  };

  return (
    // <HideOnScroll>
    <StyledAppBar position="fixed">
      <StyledToolbar>
        <Link to="/" onClick={handleOnClick}>
          <Logo showmobile={true} alt="Mayerchak Group Logo" role="presentation" src={LogoSVG} />
        </Link>
        {/* <StyledBlock showmobile={true}>Avro Business Park</StyledBlock> */}
        <StyledIconButton
          edge="start"
          aria-label="open drawer"
          // open={handleToggle}
          onClick={handleToggle}
        >
          <StyledMenuIconButton />
        </StyledIconButton>

        {/* <StyledBlock hidemobile={false}></StyledBlock> */}
      </StyledToolbar>
      {/* TODO close drawer when window is resized */}
      <Drawer
        open={open}
        // containerStyle={styles.navBar}
        PaperProps={{ component: StyledPaper }}
        onClick={handleToggle}
        onKeyDown={handleToggle}
        style={{ maxHeight: "calc(100svh - 120px)", overflow: "scrollY" }}
        role="presentation"
        anchor="right"
        id="Drawer"
      >
        <DrawerNavContainer id="drawer-nav-container">
          <Grid container justifyContent={"space-between"} alignItems={"flex-start"}>
            <Grid item>
              <Link to="/" onClick={props.handleNavClick} style={{ marginBottom: "1rem" }}>
                <DrawerLogo src={LogoReversed} />
              </Link>
            </Grid>
            <Grid item>
              <Close style={{ alignSelf: "flex-end" }} />
            </Grid>
          </Grid>
          {menuData.map((menuItem, index) => {
            return (
              <Link
                key={menuItem.to}
                activeClass="active"
                spy={true}
                hashSpy={true}
                smooth={true}
                offset={menuItem.options.offset}
                duration={menuItem.options.duration}
                // color="common.white"

                to={menuItem.to}
                onClick={handleOnClick}

                // className={location.pathname.indexOf("/leasing") >= 0 ? "active" : null}
              >
                {menuItem.label}
              </Link>
            );
          })}
        </DrawerNavContainer>
        <Typography variant="caption" ml="1rem">
          All text and images copyright ©2023 Remington Development Corporation. All rights
          reserved.
        </Typography>
      </Drawer>
    </StyledAppBar>
    // </HideOnScroll>
  );
}
const StyledAppBar = styled(AppBar)`
  @media (orientation: landscape) and (max-height: 400px) {
    display: none;
  }
`;
const StyledIconButton = styled(IconButton)`
  margin-left: auto;
  margin-right: 1.8rem;
  background-color: transparent;
`;

const StyledMenuIconButton = styled(MenuIcon)`
  background-color: transparent;
  width: 1.3em;
  height: 1.3em;
  color: ${props => props.theme.palette.secondary.main};
`;

const StyledPaper = styled("div")`
  overflow: hidden;
  background-color: ${props => props.theme.palette.primary.main}!important;
  background-image: url(${HalfStarBottom});
  background-repeat: no-repeat;
  background-position: center calc(100% - 2rem);
  background-size: contain;
  padding-top: 2rem;
  padding-right: 2rem;

  width: 300px;
  @media (min-width: 480px) {
    width: 300px;
  }
`;

const DrawerNavContainer = styled("nav")`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 250px;
  padding: 0 0 1.5rem 3rem;
  flex: 0;

  ${props => props.theme.breakpoints.up("md")} {
    //TODO
  }
  @media (min-width: 480px) {
    width: 100%;
  }

  .MuiButton-contained {
    margin-top: 1rem;
    background-color: #f50057;
    padding: 1rem 1rem;
  }
  .MuiButton-label {
    font-size: 1rem;
    font-style: normal;
    color: ${props => props.theme.palette.common.white};
  }
  button {
    &.active {
      // color: ${props => props.theme.palette.secondary.dark};
      font-family: "Poppins", sans-serif;
      font-weight: 800;
      &:before {
        display: inline-block;
        position: absolute;
        left: 35px;
        color: ${props => props.theme.palette.primary.dark};
        content: ">";
      }
    }
  }
  a {
    padding: 0;
    background-color: transparent;
    text-decoration: none;
    margin: 0.35em;
    color: ${props => props.theme.palette.common.white};
    font-family: "Poppins", sans-serif;
    &.active {
      // color: ${props => props.theme.palette.secondary.dark};
      font-family: "Poppins", sans-serif;
      font-weight: 800;
      &:before {
        display: inline-block;
        position: absolute;
        left: 35px;
        color: ${props => props.theme.palette.primary.dark};
        content: ">";
      }
    }
    &:before {
      /* display: inline-block;
      position:relative */
      content: "";
    }
  }
`;
// const StyledAppBar = styled(AppBar)``;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.palette.common.white};
  width: 100%;
  height: 58px;

  /* max-height: 55px; */
  /* margin-bottom: 25px; */

  z-index: 500;
  @media (min-width: 480px) {
    background-color: ${props => props.theme.darkBlue};
    height: 66px;
    /* max-height: 100px; */
  }
`;

const DrawerLogo = styled("img")`
  /* max-height: 15; */
  // width: 5rem;
  // height: auto;
  /* margin-bottom: 2em; */
  width: auto;
  height: 66px;
`;

const Logo = styled("img")`
  width: auto;
  height: 2.5rem;
  @media (min-width: 480px) {
    margin-top: 0.3em;
  }
`;
